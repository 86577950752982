<template>
  <div class="section-one">
    <draggable
      v-model="dashboardSort.section_1"
      class="section-one"
      :class="isDisable ? 'section-one-in-active' : ''"
      group="card-boxes"
      :disabled="isDisable"
      @change="updateOrder"
    >
      <v-card
        v-for="(row, i) in dashboardSort?.section_1"
        :key="i"
        class="box"
        :loading="loading"
      >
        <!-- Card content here -->
        <div
          class="box-wrapper"
          :style="{ 'background-color': row.card_color_code }"
        >
          <!-- Single Value -->
          <div
            v-if="row.keys?.length == 1"
            class="single-value"
          >
            <div class="header">
              <span class="icon">
                <v-icon>{{ getIcon(row.keys[0]) }}</v-icon>
              </span>
              <!-- <span class="title">aaa</span> -->
              <span>
                <ColorPicker
                  v-if="!isDisable"
                  v-model="row.card_color_code"
                  @change="changeCardColor(row)"
                />
              </span>
            </div>
            <div class="content">
              <div>
                <span class="title">{{ row.card_name }}</span>
                <span class="value">{{ dashboadData[row.keys[0]] }}</span>
              </div>

              <div
                v-if="row.card_name !== 'Upcoming Maintenance'"
                class="SI-Units"
              >
                <span v-if="vehicleTech == 'ice' && row.vehicle_tech == 'ice'">
                  {{
                    $t(`units.${row.keys[0]}-${vehicleTech}`)
                      | currencyFormat(authUser)
                  }}
                </span>
                <span v-else>
                  {{ $t(`units.${row.keys[0]}`) | currencyFormat(authUser) }}
                </span>
              </div>
            </div>
          </div>
          <!-- Single Value -->
          <!-- Multi Value -->
          <!--  -->
          <div
            v-else-if="row.keys?.length > 1"
            class="multi-value"
          >
            <div class="header">
              <span class="title">
                {{ row.card_name }}
              </span>
              <span class="d-flex align-center">
                <span class="icon">
                  <v-icon v-if="row.card_name == 'Vehicle Status'">
                    mdi-atv
                  </v-icon>
                  <v-icon v-else-if="row.card_name == 'Trips'">
                    mdi-road-variant
                  </v-icon>
                </span>
                <span>
                  <ColorPicker
                    v-if="!isDisable"
                    v-model="row.card_color_code"
                    @change="changeCardColor(row)"
                  />
                </span>
              </span>
            </div>
            <div class="content">
              <!-- Vehicle status -->
              <div
                v-if="row.card_name == 'Vehicle Status'"
                class="Vehicle-Status"
                :class="options.tenant_id && 'active'"
              >
                <div
                  v-for="(val, st) in row.keys"
                  :key="st"
                  class="content-line"
                  :class="val"
                  @click="options.tenant_id && goToTelematics(val)"
                >
                  <div>
                    <v-icon>mdi-circle</v-icon>
                    <span class="title">{{ $t(`vehicle_${val}`) }}</span>
                  </div>
                  <div class="value">
                    {{ dashboadData[val] }}
                  </div>
                </div>
              </div>
              <!-- Vehicle status -->

              <div v-else-if="row.card_name == 'Trips'">
                <div
                  v-for="(val, st) in row.keys"
                  :key="st"
                  :class="val == 'active_trips' && 'active'"
                  @click="val == 'active_trips' && openActiveModal()"
                >
                  <div class="content-line">
                    <div>
                      <span class="title">{{ $t(`${val}`) }}</span>
                    </div>
                    <div class="value">
                      {{ dashboadData[val] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Multi Value -->
        </div>
      </v-card>
    </draggable>
    <!--  -->
    <!--  -->
    <Modal
      v-if="isActiveModal"
      title="active_trips"
      :is-visible="isActiveModal"
      is-view
      :toggle="() => (isActiveModal = false)"
    >
      <active-trips :options="options" />
    </Modal>
    <!--  -->
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
export default {
  components: {
    draggable,
    Modal: () => import("@/components/Modals/Modal.vue"),
    ActiveTrips: () => import("../utils/ActiveTrips.vue"),
    ColorPicker: () => import("@/components/base/ColorPicker.vue"),
  },

  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    vehicleTech: {
      type: String,
      default() {
        return "";
      },
    },
  },

  data() {
    return {
      isActiveModal: false,
      icons: {
        total_avg_range: "mdi-car-speed-limiter",
        total_avg_speed: "mdi-speedometer",
        average_mileage: "mdi-road-variant",
        avg_efficincy_in_pkr_km: "mdi-map-marker-distance",
        avg_efficincy_in_pkr_kg: "mdi-weight",
        avg_time_to_charge: "mdi-ev-station",
        upcoming_maintenance: "mdi-cog",
        average_soc: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "dashboard/getIsLoading",
      dashboadData: "dashboard/getDashboadData",
      dashboardSort: "dashboard/getDashboadSortData",
      //
      selectedCards: "dashboard/getSelectedCards",
    }),
    sectionOne() {
      return this.dashboardSort?.section_1;
    },
    isDisable() {
      return this.selectedCards.length == 0 ? true : false;
    },
  },
  methods: {
    getIcon(key) {
      return this.icons[key] || "mdi-help";
    },

    //
    goToTelematics(val) {
      const params = {};
      if (this.authUser.isAdmin) {
        if (this.options.product == "oem") {
          params.product = this.options.product;
          params.tenant_id = this.options.tenant_id;
        } else if (this.options.product == "fleet") {
          params.product = this.options.product;
          params.fleetId = this.options.fleetId;
          params.oem_b2b_ids = this.options.oem_b2b_ids;
          params.tenant_id = this.options.tenant_id;
        }
      }
      //if (this.authUser.user_nature == "fleet")
      else {
        params.tenant_id = this.options.tenant_id;
      }
      if (val == "active_vehicles") {
        params.status = "active";
      } else if (val == "in_active_vehicles") {
        params.status = "inactive";
      } else if (val == "offline_vehicles") {
        params.status = "offline";
      }

      return this.$router.push({
        name: "vehicle_telematics",
        query: params,
      });
    },
    openActiveModal() {
      this.isActiveModal = true;
    },
    //
    updateOrder() {
      const params = {
        section: "section_1",
        sort: true,
        item: this.dashboardSort.section_1,
        vehicle_tech: this.vehicleTech,
      };

      this.$store.dispatch("dashboard/updateCard", params);
    },
    changeCardColor(row) {
      const params = {
        section: "section_1",
        user_card_id: row.id,
        card_color_code: row.card_color_code,
      };
      this.$store.dispatch("dashboard/updateCard", params);
    },
  },
};
</script>
<style lang="sass" scoped>
$primaryColor : #23BDAA

.section-one-in-active
  .box
    cursor: auto !important

.section-one
    display: flex
    flex: 1
    gap: 0.5rem
    flex-wrap:    wrap
    height: fit-content
    .box
        cursor: all-scroll
        user-select: none
        height: 170px
        background: #d5f1d5 !important
        // min-width: 230px
        flex-basis: 49%
        margin: 0px
        &:hover
            box-shadow: 2px 1px 8px #20a390

        .box-wrapper
            height: inherit
            padding: 1rem 0.5rem
            .header
                display: flex
                justify-content: space-between
                align-items: center
                .title
                    font-weight: 600
                    font-size: 14px !important
                .icon
                    width: 40px
                    height: 40px
                    display: flex
                    justify-content: center
                    align-items: center
                    border-radius: 100%
                    background: $primaryColor
                    i
                        color: white
                        font-size: 2rem


            .content
                div
                    display: flex
                    justify-content: space-between
                    align-items: center
                .title
                    font-weight: 600
                    font-size: 14px !important
                .value
                    font-weight: 600
                    font-size: 16px

                .SI-Units
                    font-size: 11px

            .single-value, .multi-value
                height: -webkit-fill-available
                display: flex
                flex-direction: column
                justify-content: space-between

            .multi-value
                .content
                    div
                        display: block
                    .content-line
                        display: flex
                        justify-content: space-between
                        .title
                            font-weight: normal
                            font-size: 12px !important


.Vehicle-Status
    i
        margin-right: 0.5rem
        font-size: 12px

    .active_vehicles
        i, .value
            color: #4CAF50 !important

    .in_active_vehicles
        i, .value
            color: #f0ae38 !important

    .offline_vehicles
        i, .value
            color: #ff0000 !important

.active
    .content-line
        cursor: pointer
        &:hover
            text-decoration: underline
            font-style: italic



@media only screen and (max-width: 960px)
  .box
    flex-basis: 100% !important
</style>
